import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"

export default function ThanksForSubscribing() {
  return (
    <Layout>
      <SEO title="Thanks For Subscribing" />
      <h2 className="text-3xl">One last thing 📥</h2>
      <h3>
        Thanks for smashing that subscribe button. Make sure to check your inbox
        and confirm your subscription.
      </h3>
    </Layout>
  )
}
